<template>
  <v-text-field
    v-bind="$attrs"
    :key="key"
    :value="internalValue"
    :label="label"
    :disabled="disabled"
    :required="required"
    :error-messages="errorMessage"
    :rules="validationRules"
    g-mask="integer"
    :class="marginTop?'mt-4':'pt-1'"
    @input="handleInput"
    @blur="handleBlur"
  />
</template>

<script>
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'
  import { RULES } from '@/components/forms'
  import { isValidNumber } from '@/utils/general'

@Component
  export default class GCostField extends Vue {
  @Prop({ type: [Number], default: 4 }) minDigits;
  @Prop({ type: [Number], default: 10 }) maxDigits;
  @Prop({ type: [Number], default: Infinity }) maxAmount;
  @Prop({ type: [Boolean], default: false }) required;
  @Prop({ type: [Boolean], default: true }) marginTop;
  @Prop({ type: [String], default: null }) errorMessage;
  @Prop({ type: [Boolean], default: false }) noZeroStart;
  @Prop({ type: [Boolean], default: false }) disabled;
  @Prop({ type: [String], default: 'Costo' }) label;
  @Model('input', { default: null }) value;

  key = null

  get internalValue () {
    return this.value
  }

  @Emit('input')
  handleInput (newValue) {
    return newValue
  }

  @Emit('blur')
  handleBlur (newValue) {
    return newValue
  }

  mounted () {
    this.key = this.generateRandomId()
  }

  generateRandomId () {
    return '_' + Math.random().toString(36).substring(2, 11)
  }

  get validationRules () {
    const { required, noZeroStart } = this

    const rules = [
      value => value === null || value === '' || (isValidNumber(value) && value.toString().length <= this.maxDigits) || `El ${this.label.toLowerCase()} no puede tener más de ${this.maxDigits} dígitos.`,
      value => value === null || value === '' || (isValidNumber(value) && parseFloat(value) <= this.maxAmount) || `El ${this.label.toLowerCase()} no puede superar ${this.maxAmount}.`,
      value => !(value && value.toString().startsWith('00')) || `El ${this.label.toLowerCase()} no puede iniciar con 00.`,
    ]

    if (noZeroStart) {
      rules.push(value => (value && !value.toString().startsWith('0')) || `El ${this.label.toLowerCase()} no puede iniciar con 0.`)
    }

    if (required) {
      rules.push(value => RULES.isAmount[0](value))
      rules.push(value => value === null || value === '' || (isValidNumber(value) && value.toString().length >= this.minDigits) || `El ${this.label.toLowerCase()} debe tener al menos ${this.minDigits} dígitos.`)
    }

    return rules
  }
  }
</script>
