
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { Component as VehicleComponent, Generation } from '@/entities/vehicle'
  import { Inspection } from '@/entities/purchase'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import ManualContent from '@/components/forms/inspection/steppers/content/transmition/ManualContent.vue'
  import AutomaticContent from '@/components/forms/inspection/steppers/content/transmition/AutomaticContent.vue'

@Component({
  components: { AutomaticContent, ManualContent },
})
  export default class TransmissionContent extends FilesProcess {
  @Prop({ type: Number, default: null }) idProcessInspection: number
  @Prop({ type: Object, default: () => null }) transmission: VehicleComponent
  @Prop({ type: Object, default: () => ({}) }) photoProperties: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) inspection: Inspection
  @Prop({ type: Object, default: () => ({}) }) generation: Generation
  @Prop({ type: Number, default: null }) idProcess: number
  @Prop({ type: Boolean, default: false }) disabled: boolean

  componentValue = null
  cost = 0

  get allData () {
    const { transmission, generation } = this

    return {
      transmission,
      generation,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  async onCategoryChange (val) {
    this.componentValue = val.transmission?.values?.find(value => value.id === val?.generation?.transmission?.componentValue?.id)
  }

  get isManual () {
    return Boolean(this.componentValue?.isManual)
  }

  next () {
    this.$emit('input', { cost: this.cost })
    this.$emit('next')
  }
  }
